<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-button v-b-modal.new-modal variant="success"> تسویه حساب جدید </b-button>
        <b-card title="تسویه حساب با فروشندگان" class="mt-3">
            <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-3">
                                <b-form-group label="فروشنده" label-for="seller">
                                    <multiselect id="seller" class="form-group" :options="sellers" label="name"
                                        track-by="id" v-model="searchParams.seller" placeholder="" :searchable="true"
                                        :close-on-select="true" :show-labels="false">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group label="وضعیت" label-for="status">
                                    <b-form-select id="status" v-model="searchParams.status">
                                        <b-form-select-option value="completed">تسویه شده</b-form-select-option>
                                        <b-form-select-option value="pending">تسویه نشده</b-form-select-option>
                                        <b-form-select-option value="canceled">کنسل شده</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group id="start_date" label="از تاریخ " label-for="start_date">
                                    <date-picker type="datetime"
                                        format="jYYYY-jMM-jDD HH:mm" />
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group id="end_date" label=" تا تاریخ" label-for="end_date">
                                    <date-picker type="datetime" 
                                        format="jYYYY-jMM-jDD HH:mm" />
                                </b-form-group>
                            </div>

                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(total)="data">
                    {{data.item.total | price}}
                </template>
                <template v-slot:cell(provides)="data">
                    <span v-if="data.item.provides != []">
                        <span v-for="(provide, index) in data.item.provides" :key="index">{{provide.id}}
                            {{ index + 1 != data.item.provides.length ? ' ، ' : '' }}</span>
                    </span>
                </template>
                <template v-slot:cell(seller)="data">
                    {{ data.item.seller ? data.item.seller.name + ' ' + data.item.seller.last_name + ' - شرکت : ' + data.item.seller.company_name: '' }}
                </template>
                <template v-slot:cell(status)="data">
                    <span v-if="data.item.status == 'pending'" class="badge badge-warning">پرداخت نشده</span>
                    <span v-else-if="data.item.status == 'completed'" class="badge badge-success">پرداخت شده</span>
                    <span v-else-if="data.item.status == 'canceled'" class="badge badge-danger">کنسل شده</span>
                </template>
                <template v-slot:cell(details)="data">
                    <button @click="showItem(data.item)" class="btn btn-info">
                        <i class="fa fa-info"></i>
                    </button>
                </template>
                <template v-slot:cell(pay)="data">
                    <button @click="payment(data.index)" class="btn btn-primary"
                        :disabled="data.item.status == 'pending' ? false : true">
                        پرداخت
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>
        <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
        </pagination>
        <pagination :limit="2" v-else-if="items.data && search" :data="items.data"
            @pagination-change-page="searchItems">
        </pagination>
        <b-modal id="new-modal" title="تسویه حساب جدید" hide-footer size="lg">
            <!-- <b-form-group>
                <label for=""> تسویه حساب <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-select v-model="pay_offs">
                    <b-form-select-option value="1">دستی</b-form-select-option>
                    <b-form-select-option value="2">اتومات</b-form-select-option>
                </b-form-select>
            </b-form-group> -->
            <form @submit.prevent="newItem">
                <template v-if="pay_offs == '1'">
                    <b-form-group>
                        <label for=""> فروشنده <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <multiselect :allow-empty="false" id="seller" class="form-group" :options="sellers" label="name"
                            track-by="id" v-model="selected_seller" placeholder="" :searchable="true"
                            :close-on-select="true" :show-labels="false" @input="loadProvides">
                        </multiselect>
                    </b-form-group>
                </template>
                <b-table responsive striped hover v-if="selected_seller" :fields="provide_fields"
                    :items="seller_provides">
                    <template v-slot:cell(select)="data">
                        <b-form-checkbox class="text-right text-dark table-checkbox" :id="'checkbox' + data.item.id"
                            :name="'checkbox' + data.item.id" @change="provideSelect(data.item.id)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(created_at)="data">
                        {{data.item.created_at | persianDate}}
                    </template>
                    <template v-slot:cell(amount)="data">
                        {{data.item.amount | price}}
                    </template>
                    <template v-slot:cell(seller_amount)="data">
                        {{data.item.seller_amount | price}}
                    </template>
                    <template #head(select)="data">
                        <b-form-checkbox :disabled="seller_provides == '' ? true : false" class="text-right text-dark"
                            :id="'checkbox-all' + data.label" name="checkboxall" @change="provideSelectedAll">
                        </b-form-checkbox>
                    </template>
                </b-table>
                <div class="alert alert-danger text-right" v-if=" selected_seller && seller_provides == ''">
                    <span class="text-right">سفارش پرداخت نشده ای وجود ندارد </span>
                </div>
                <div class="text-center">
                    <button :disabled="(disabled || selected_provides == '') ? true : false" class="btn btn-success"
                        type="submit">صدور فاکتور</button>
                </div>
            </form>
        </b-modal>
        <b-modal id="pay-modal" hide-footer title="پرداخت فاکتور">
            <form @submit.prevent="payOff" id="pay-off">
                <b-form-group>
                    <label for=""> کد پیگیری : <i class="fas fa-asterisk text-danger"
                            style="font-size: 0.5rem;"></i></label>
                    <b-form-input id="tracking-code" name="tracking_code"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for=""> وضعیت : <i class="fas fa-asterisk text-danger"
                            style="font-size: 0.5rem;"></i></label>
                    <b-form-select name="status">
                        <b-form-select-option value="pending"> پرداخت نشده</b-form-select-option>
                        <b-form-select-option value="completed">پرداخت شده</b-form-select-option>
                        <b-form-select-option value="canceled">کنسل شده</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group>
                    <label for=""> توضیحات :
                    </label>
                    <b-form-textarea name="description"></b-form-textarea>
                </b-form-group>
                <div class="text-center">
                    <button class="btn btn-success" type="submit">
                        پرداخت
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="show-modal" hide-footer title="جزئیات پرداخت" size="lg">
            <div v-if="edit" >
                <div class=" py-1">
                    <span class="fontWeightBold">نام و نام خانوادگی فروشنده : </span>
                    <span>{{edit.seller ? edit.seller.name + ' ' + edit.seller.last_name : ''}}</span>
                </div>
                <div class=" py-1">
                    <span class="fontWeightBold">کد پیگیری : </span>
                    <span>{{edit.tracking_code != null ? edit.tracking_code :'-'}}</span>
                </div>
                <div class="py-1">
                    <span class="fontWeightBold">وضعیت : </span>
                    <span v-if="edit.status == 'completed'" class="badge badge-success">پرداخت شده</span>
                    <span v-if="edit.status == 'pending'" class="badge badge-warning">پرداخت نشده</span>
                    <span v-if="edit.status == 'canceled'" class="badge badge-danger">کنسل شده</span>
                </div>
                <div class=" py-1">
                    <span class="fontWeightBold">توضیحات : </span>
                    <span>{{edit.description}}</span>
                </div>
                <b-table responsive striped hover class="mt-4" :fields="provide_details_fields"
                    :items="edit.provides">
                    <template v-slot:cell(created_at)="data">
                        {{data.item.created_at | persianDate}}
                    </template>
                    <template v-slot:cell(seller_amount)="data">
                        {{data.item.pivot.seller_amount | price}}
                    </template>
                    <template v-slot:cell(product)="data">
                        <button @click="showProvide(data.item)" class="btn btn-info">
                            اقلام
                        </button>
                    </template>
                    
                </b-table>
            </div>
        </b-modal>
        <provideModal :orders="provide_details.orders" />
    </div>
</template>
<script>
    import provideModal from './provideModal.vue'
    import mixins from '../mixins/mixins'
    export default {
        components:{
            provideModal
        },
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/pay_offs',
                title: 'تسویه حساب با فروشندگان ',
                table_fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'seller',
                        label: 'فروشنده'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'tracking_code',
                        label: 'کد پیگیری'
                    },
                    {
                        key: 'total',
                        label: 'مبلغ پرداختی (تومان)'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                    {
                        key: 'provides',
                        label: 'شناسه سفارش ها'
                    },
                    {
                        key: 'details',
                        label: 'جزئیات'
                    },
                    {
                        key: 'pay',
                        label: 'پرداخت'
                    },
                ],
                provide_fields: [{
                        key: 'select',
                        label: ''
                    },
                    {
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'seller_amount',
                        label: 'سهم فروشنده (تومان)'
                    },
                    {
                        key: 'amount',
                        label: 'مبلغ کل (تومان)'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                ],
                provide_details_fields: [{
                        key: 'select',
                        label: ''
                    },
                    {
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'invoice_id',
                        label: 'شناسه سفارش'
                    },
                    {
                        key: 'seller_amount',
                        label: 'سهم فروشنده (تومان)'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                    {
                        key: 'product',
                        label: ''
                    },
                ],
                sellers: [],
                selected_seller: null,
                pay_offs: 1,
                seller_provides: [],
                selected_provides: [],
                provides: [],
                payment_item: {},
                provide_details: {}
            }
        },
        mounted() {
            this.loadItems()
            this.loadSellers()
            this.searchParams = {
                status: '',
                start_date: '',
                end_date: '',
                seller: '',
            }
        },
        methods: {
            loadItems(page = 1) {
                this.show = true
                this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page).then(response => {
                    this.items = response.data
                    this.show = false
                })
            },
            loadSellers() {
                this.$axios.get(this.$root.baseUrl + '/api/admin/sellers?all=true')
                    .then(response => {
                        this.sellers = response.data.data.filter(item => item.name = item.name + item.last_name +
                            ' - شرکت ' + item.company_name)
                    })
            },
            searchItems(page = 1) {
                let start = document.querySelector('#start_date input').value;
                let end = document.querySelector('#end_date input').value;
                this.disabled = true
                this.search = true
                this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
                        params: {
                            seller_id: this.searchParams.seller != null ? this.searchParams.seller.id : '',
                            status: this.searchParams.status,
                            start: start,
                            end: end
                        }
                    })
                    .then(response => {
                        this.search = true
                        this.items = response.data
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            loadProvides(event) {
                this.$axios.get(`/api/admin/sellers/${event.id}/provides` + '?not_complete=1&all=1')
                    .then(response => {
                        this.seller_provides = response.data.data
                        this.head_click = true
                    })
            },
            provideSelect(id) {
                let flag = false
                if (this.selected_provides != '') {
                    this.selected_provides.forEach((el) => {
                        if (el == id) {
                            this.selected_provides = this.selected_provides.filter(
                                (item) => item !== id
                            );
                            flag = true
                        }
                    });
                    if (!flag) {
                        this.selected_provides.push(id)
                    }
                } else {
                    this.selected_provides.push(id)
                }
            },
            provideSelectedAll(e) {
                this.selected_provides = []
                if (e) {
                    document.querySelectorAll('.table-checkbox input[type=checkbox]').forEach(item => {
                        item.checked = true
                        setTimeout(() => {
                            let event = new Event('change');
                            item.dispatchEvent(event);
                        })
                    })
                } else {
                    document.querySelectorAll('.table-checkbox input[type=checkbox]').forEach(item => {
                        item.checked = false
                    })
                }
            },
            newItem() {
                this.disabled = true
                this.$axios.post(`/api/admin/sellers/${this.selected_seller.id}/group-pay-off`, {
                        provides: this.selected_provides,
                        status: 'pending'
                    })
                    .then(response => {
                        this.$root.success_notification(response.message)
                        this.$root.$emit('bv::hide::modal', 'new-modal')
                        this.items.data.data.unshift(response.data.data)
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            payment(index) {
                let item = window.clone(this.items.data.data[index])
                item.index = index
                this.payment_item = item
                this.$root.$emit('bv::show::modal', 'pay-modal')
            },
            payOff() {
                this.disabled = true
                let form = document.getElementById('pay-off')
                let formData = new FormData(form)
                formData.append('_method', 'PUT')

                this.$axios.post(`/api/admin/sellers/${this.payment_item.seller.id}/pay_offs/${this.payment_item.id}`,
                        formData)
                    .then(response => {
                        this.$root.success_notification(response.message)
                        this.$root.$set(this.items.data.data, this.payment_item.index, response.data.data)
                        this.$root.$emit('bv::hide::modal', 'pay-modal')
                    })
                    .catch(error => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
            showProvide(item) {
                this.provide_details = item
                this.$root.$emit('bv::show::modal', 'provide-modal')
            },
        }
    }
</script>